module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":660,"showCaptions":["title"],"markdownCaptions":true,"withWebp":true,"linkImagesToOriginal":false,"backgroundColor":"transparent","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mateusz Roth | JavaScript, React/Native - expert, consultant, engineer, freelancer | React, Native, iOS, Android, Expo, Node.js, Supabase","short_name":"Mateusz Roth","start_url":"/","background_color":"#FF7538","theme_color":"#FF7538","display":"minimal-ui","icon":"./content/images/favicon-32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cf6ddc2608270b6bd4fde539426cb440"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-56596904-1","cookieName":"gatsby-gdpr-google-analytics","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-portfolio-minimal/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://mateuszroth.pl/","manifestSettings":{"favicon":"./content/images/favicon-32x32.png","siteName":"Mateusz Roth | JavaScript, React/Native - expert, consultant, engineer, freelancer | React, Native, iOS, Android, Expo, Node.js, Supabase","shortName":"Mateusz Roth","startUrl":"/","backgroundColor":"#FF7538","themeColor":"#FF7538","display":"minimal-ui"},"contentDirectory":"./content","blogSettings":{"path":"/blog","usePathPrefixForArticles":false},"googleAnalytics":{"trackingId":"UA-56596904-1","anonymize":true,"environments":["production","development"]}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
